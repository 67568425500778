import * as React from "react";

import Puzzle from "../../Puzzle";
import map from '../../images/m6.png'
import audio from '../../../static/a6.mp3'

const Page = () => {
  return (
    <Puzzle
      title="Zagadka 6"
      author="Autorka: Barbara Celebucka"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      audio={audio}
      language="pl"
      languageUrl="/riddles/znKe8aLIi5"
    >
      <div>
        <p>
          Neon „Ubezpiecz mieszkanie w PZU” na dachu budynku Kościuszkowskiej
          Dzielnicy Mieszkaniowej przy pl. Kościuszki proj. Jerzy Werszler,
          zaświecił w 1975 r. Związek szkła i światła jest nierozerwalny. Co
          jeśli we wnętrzu przeziernej formy zechcemy światło zamknąć?{" "}
        </p>
        <p>
          Szkło, rtęć i próżnia już w XVII w. wystarczyły, aby uzyskać efekt
          świecenia. W połowie XIX w. eksperymentowano z wprowadzeniem światła
          do szklanych rurek za pomocą gazu, a na szerszą skalę neony zaczęto
          produkować na początku XX w. W Polsce popularne były jeszcze w
          międzywojennej Warszawie, ale to w latach 60-tych i 70-tych XX w. ich
          masowa produkcja zmieniła krajobraz dużych miast, również Wrocławia.
          Witalność głównych ulic tworzyła ówcześnie reklama uliczna.Szklane
          rurki wypełnione gazem rozświetlały ulice nadając im dwie
          kontrastujące ze sobą cechy: wielkomiejskość i przytulność. Ocieplały
          nasze miasto, pozwalały na myślowe wędrówki w nowoczesność tworząc
          Wrocław wielkomiejskim domem. Ciepło, światło, sentyment, nostalgia -
          możemy kontynuować grę skojarzeń lub zastanowić się: za czym tak
          tęsknimy? Neony odnoszą nas do określonego momentu w historii,
          kończącego się wraz z rozwojem tańszych rozwiązań reklamowych i
          estetycznych, odnoszą nas również do własnych wspomnień lub wyobrażeń
          na temat ówczesnej aury miasta. Neon jest fizycznym obiektem, możemy
          go dotknąć i zmierzyć, ale górując nad nami w przestrzeni publicznej
          rzadko jest w naszym zasięgu, podziwiamy go z dołu, odczuwamy jego
          blask. Wypełnia wnętrza urbanistyczne, zmienia naszą percepcję
          miejsca. Modeluje przestrzeń miejską tak jak lampka nocna o ciepłym
          świetle buduje kameralność pokoju. Rozświetlenie miasta to część
          historii reklamy, historii przestrzeni publicznej i estetyki związanej
          z pewnym rozdziałem życia Wrocławia - działalnością przedsiębiorstwa
          Reklama, projektantów Janusza Tarantowicza, Tadeusza Ciałowicza, czy
          Jerzego Werszlera.
        </p>
        <p>
          Plac Kościuszki ma silnie zdefiniowane wnętrze, które wieczorami
          rozświetla odnowiony niedawno neon „Ubezpiecz mieszkanie w PZU”. To
          ostatni ruchomy, narracyjny neon we Wrocławiu działający i znajdujący
          się w swoim pierwotnym położeniu. 23 litery, 4 postacie, w sumie 700 m
          szklanych rur odnowiono w 2020 r. Nie mogę oprzeć się wrażeniu, że
          Wrocław bez skradającego się złodzieja byłby nieco inny. Od czasu
          świetności neonów sztuka użytkowa już nigdy tak czule nas nie
          otaczała. Czy to możliwe, że uformowane w różne kształty rurki szklane
          ukształtowały jakąś część nas?
        </p>
      </div>
    </Puzzle>
  );
};

export default Page;
